<template>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/AppLayout';

export default {
  name: 'Labels',
  components: { AppLayout }
}
</script>

<style scoped>

</style>
