<template>
  <AppLayout>
    <div class="h-full border-t border-b">
      <div class="md:grid md:grid-cols-1 md:gap-6">
        <form ref="form" @submit="handleSubmit">
          <div class="px-4 py-5 bg-white sm:p-6">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div class="sm:col-span-1">
                <div class="sm:col-span-1">
                  <div v-if="!editable" class="fieldTitle">
                    <MainFieldTitle :label="$t('account.fields.fullName')" :name="form.name" />
                  </div>
                  <FormInput
                      v-else
                      :label="$t('account.fields.fullName')"
                      inputName="name"
                      :value="form.name"
                      :errorMessage="formErrors.name"
                      @handleInputChange="handleInputChange"
                  />
                </div>
              </div>
              <div class="sm:col-span-1">
                <div class="sm:col-span-1">
                  <div v-if="!editable" class="fieldTitle">
                    <MainFieldTitle :label="$t('account.fields.title')" :name="form.title" />
                  </div>
                  <FormInput
                      v-else
                      :label="$t('account.fields.title')"
                      inputName="title"
                      :value="form.title"
                      :errorMessage="formErrors.title"
                      @handleInputChange="handleInputChange"
                  />
                </div>
              </div>
              <div class="sm:col-span-1">
                <div class="sm:col-span-1">
                  <div v-if="!editable" class="fieldTitle">
                    <MainFieldTitle :label="$t('account.fields.email')" :name="form.email" />
                  </div>
                  <FormInput
                      v-else
                      :label="$t('account.fields.email')"
                      inputName="email"
                      :value="form.email"
                      @handleInputChange="handleInputChange"
                      disabled
                  />
                </div>
              </div>
              <div class="sm:col-span-1">
                <div class="sm:col-span-1">
                  <div v-if="!editable" class="fieldTitle">
                    <MainFieldTitle :label="$t('account.fields.dimensionCode')" :name="form.fennoa_dimension_code" />
                  </div>
                  <FormInput
                      v-else
                      :label="$t('account.fields.dimensionCode')"
                      inputName="fennoa_dimension_code"
                      :value="form.fennoa_dimension_code"
                      :errorMessage="formErrors.fennoa_dimension_code"
                      @handleInputChange="handleInputChange"
                  />
                </div>
              </div>
              <div class="sm:col-span-1">
                <div class="grid grid-cols-1 gap-x-4 gap-y-8">
                  <div class="sm:col-span-1">
                    <div v-if="!editable" class="fieldTitle">
                      <MainFieldTitle :label="$t('account.fields.language')" :name="form.lang === 'en' ? 'English' : 'Suomi'" />
                    </div>
                    <LanguageOptions
                        v-else
                        :language="form.lang"
                        @handleInputChange="handleInputChange"
                    />
                  </div>
                  <div class="sm:col-span-1">
                    <div class="flex flex-col items-start justify-start gap-2">
                      <label class="text-sm mt-20 font-medium text-gray-500">{{ $t('global.signInWithGoogle') }}</label>
                      <div class="w-full relative rounded-md">
                        <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onCurrentUser="updateCurrentUser" ></GoogleLogin>
                        <GoogleLogin :params="params" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-gray-700" :onSuccess="onSuccessLogout" :logoutButton=true>{{ $t('global.disconnect') }}</GoogleLogin>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sm:col-span-1">
                <p class="text-sm font-medium text-gray-500" aria-hidden="true">{{ $t('account.fields.photo') }}</p>
                <AccountPicture :account="form" :editable="editable" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LanguageOptions from '../../components/SelectOptions/LanguageOptions/LanguageOptions';
import AccountPicture from './AccountPicture/AccountPicture';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import AppLayout from '@/components/Layout/AppLayout';
import handleChangeLocale from '@/helpers/handleChangeLocale';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import { EventBus } from '@/eventBus/event-bus';
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';
import GoogleLogin from 'vue-google-login';

export default {
  name: 'AccountSettings',
  components: {
    MainFieldTitle,
    FormInput,
    AppLayout,
    AccountPicture,
    LanguageOptions,
    ErrorMessage,
    GoogleLogin
  },
  data() {
    return {
      editable: false,
      form: {
        name: '',
        title: '',
        fennoa_dimension_code: '',
        lang: '',
        email: '',
        avatar: ''
      },
      formErrors: {
        name: [],
        title: [],
        fennoa_dimension_code: []
      },
      params: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: process.env.VUE_APP_GOOGLE_SCOPE,
        prompt: 'consent',
        include_granted_scopes:true
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true
      }
    }
  },
  computed: {
    ...mapGetters([
        'account',
        'updateAccountSuccess',
        'updateAccountError',
        'updateAccountResponseMessage',
        'updateAvatarSuccess',
        'updateAvatarError',
        'updateAvatarResponseMessage'
    ])
  },
  watch: {
    account(newValue) {
      this.form = newValue;
    },
    updateAccountSuccess(newValue) {
      if (newValue) {
        EventBus.$emit('formSubmitted', true);
        this.editable = false;
        this.toastMessage(this.updateAccountResponseMessage, 'success');
      }
    },
    updateAccountError(newValue) {
      newValue && this.handleRequestErrorMessage(newValue);
    },
    updateAvatarSuccess(newValue) {
      if (newValue) {
        this.toastMessage(this.updateAvatarResponseMessage, 'success');
        handleChangeLocale(this.form.lang);
      }
    }
  },
  mounted() {
    this.form = { ...this.account };
    EventBus.$on('handleEdit', editable => {
      this.editable = editable;
      this.form = { ...this.account };
      this.formErrors = {};
    });
    EventBus.$on('handleSave', () => this.handleSubmit());
  },
  methods: {
    ...mapActions({
      handleUpdateAccountSettings: 'updateAccountSettings',
      handleUpdateCalendarId: 'updateCalendarId'
    }),
    updateCurrentUser(googleUser){
      if (googleUser){
        const accessToken = googleUser.getAuthResponse().access_token
        localStorage.accessToken = accessToken
      }
    },
    onSuccessLogout(){
      localStorage.removeItem('accessToken')
    },
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
    },
    handleSubmit() {
        this.handleUpdateAccountSettings(this.form);
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    },
    handleRequestErrorMessage(isError) {
      if (isError) {
        const { message, errors } = this.updateAccountResponseMessage;
        this.formErrors = { ...this.formErrors, ...errors };
        this.toastMessage(message, 'error');
      }
    },
    onSuccess(googleUser) {
     const profile = googleUser.getBasicProfile()
     const email = profile.getEmail()
     this.updateCurrentUser(googleUser)
     this.handleUpdateCalendarId({ google_calendar_id:email })
    }
  },
  destroyed() {
    EventBus.$off('handleSave');
    this.$toast.clear();
    this.$destroy();
  }
}
</script>

<style>
.abcRioButton:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
}
</style>
