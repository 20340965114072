<template>
  <div>
    <dt class="text-sm font-medium text-gray-500">
      {{ label }}
    </dt>
    <dd class="mt-1 text-sm text-gray-900">
      {{ name }}
    </dd>
  </div>
</template>

<script>
export default {
  name: 'MainFieldTitle',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
