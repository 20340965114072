<template>
  <div class="w-full">
    <dt class="text-sm font-medium text-gray-500">
      {{ $t('account.fields.language') }}
    </dt>
    <div class="mt-1 relative" v-click-outside="closeDropdown">
      <button
          @click="isOpen = !isOpen"
          type="button"
          class="cursor-pointer bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none sm:text-sm"
      >
        <span class="block truncate">{{ selectedLanguage === 'en' ? $t('global.languages.english') : $t('global.languages.finnish') }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
       </span>
      </button>
      <div class="absolute mt-1 w-full rounded-md bg-white z-100">
        <ul v-show="isOpen"
            class="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <li @click="select('en')" role="option"
              :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                      'text-white bg-primary': selectedLanguage === 'en',
                      'text-gray-900 hover:bg-gray-50': selectedLanguage !== 'en'
              }]"
          >
            <span class="font-normal block truncate">{{ $t('global.languages.english') }}</span>
            <span
                v-if="selectedLanguage === 'en'"
                class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="selectedLanguage === 'en' ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
          </li>
          <li @click="select('fi')" role="option"
              :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                      'text-white bg-primary ': selectedLanguage === 'fi',
                      'text-gray-900 hover:bg-gray-50': selectedLanguage !== 'fi'
              }]"
          >
            <span class="font-normal block truncate">{{ $t('global.languages.finnish') }}</span>
            <span
                v-if="selectedLanguage === 'fi'"
                class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="selectedLanguage === 'fi' ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
name: 'LanguageOptions',
  directives: {
    ClickOutside
  },
  props: {
    language: {
      type: String
    }
  },
  data() {
    return {
      isOpen: false,
      selectedLanguage: ''
    }
  },
  watch: {
    language(newValue) {
      this.selectedLanguage = newValue;
    }
  },
  mounted() {
    this.selectedLanguage = this.language;
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    select(language) {
      this.selectedLanguage = language;
      this.$emit('handleInputChange', 'lang', language);
      this.closeDropdown();
    }
  }
}
</script>

<style scoped>

</style>
