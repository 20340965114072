export default [
    '.apng',
    '.bmp',
    '.gif',
    '.jpg',
    '.jpeg',
    '.jfif',
    '.pjpeg',
    '.pjp',
    '.png',
    '.svg',
    '.tif',
    '.tiff',
    '.webp'
];
