<template>
  <div>
    <div class="mt-6 flex-grow lg:mt-0 lg:flex-grow-0 lg:flex-shrink-0">
      <div class="relative rounded-full overflow-hidden lg:block">
        <div v-if="avatar" class="w-40 h-40">
          <img
              class="relative rounded-full h-full w-full"
              :src="avatar"
              alt="Avatar">
        </div>
        <div v-else class="w-40 h-40 rounded-full bg-gray-200 flex items-center text-center">
          <span class="block w-full text-4xl">{{ account.name | getNameAbbr }}</span>
        </div>
        <label
            v-if="editable"
            for="user-photo"
            class="absolute cursor-pointer inset-0 w-40 h-40 rounded-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100">
          <span>{{ $t('account.change') }}</span>
          <span class="sr-only">{{ $t('account.userPhoto') }}</span>
          <input
              type="file"
              id="user-photo"
              name="user-photo"
              class="absolute inset-0 w-full h-full opacity-0 cursor-pointer rounded-md hidden"
              ref="avatarFile"
              @change="uploadAvatar"
              :accept="supportedFormats.join(',')"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import imageSupportedFormats from '../../../helpers/imageSupportedFormats';
import { mapActions, mapGetters } from 'vuex';

export default {
name: 'AccountPicture',
  props: {
    account: {
      type: Object
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      supportedFormats: imageSupportedFormats,
      avatar: ''
    }
  },
  watch: {
    account(newValue) {
      this.avatar = newValue.avatar;
    }
  },
  mounted() {
    this.avatar = this.account.avatar;
  },
  methods: {
    ...mapActions({
      handleUploadAccountImage: 'uploadAccountImage'
    }),
    uploadAvatar() {
      const file = this.$refs.avatarFile.files[0];
      const fileExtension = `.${file.name.split('.')[file.name.split('.').length - 1]}`;
      if (!this.supportedFormats.find(ext => ext === fileExtension)) {
        // this.toastMessage(this.$t('account.fields.unsupportedFieFormat'), 'error');
      }
      const formData = new FormData();
      formData.append('file', file);
      this.handleUploadAccountImage(formData)
    },
    resetImage(e) {
      e.stopPropagation();
      this.avatar = '';
      // this.$store.dispatch('deleteAccountAvatar').then(response => this.toastMessage(response.data.message, 'success'));
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style lang="scss" scoped>
  //img {
  //  border-radius: 50% !important;
  //  max-width: 200px;
  //  //min-width: 200px !important;
  //  max-height: 200px;
  //  //min-height: 200px !important;
  //}
</style>
